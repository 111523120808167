import MoreVertIcon from "@mui/icons-material/MoreVert";
import avartarLogo from "../../../assets/avatar.png";
import Divider from "@mui/material/Divider";
import MaterialButton from "../Button/Button";
import { useState } from "react";
import ListItem from "@mui/material/ListItem";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { avatars } from "./../../../characters/avatars";
import AuthService from "../../../services/AuthService";
import AvatarPreview from "./../../AvatarPreview";

const SpaceMembersList = ({
  spaceDetails,
  allMembersList,
  addUserClickedmainFunc,
}) => {
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [memberSearchBox, setMemberSearchBox] = useState(false);
  const [searchSelectedMember, setSearchSelectedMember] = useState(null);

  const [selectedMemberEmail, setSelectedMemberEmail] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // ----------Member search------------
  const searchMember = allMembersList.filter((member) => {
    if (email === "") {
      return member;
    } else {
      return member.email.toLowerCase().includes(email);
    }
  });

  const userDetails = AuthService.getInstance().getUserDetails();

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {/* ----------------Modal----------------- */}
      {selectedMemberEmail !== null && (
        <Modal
          isCentered
          onClose={() => setModalOpen(false)}
          isOpen={modalOpen}
          motionPreset="slideInBottom"
        >
          <ModalContent style={{ width: 350, border: "1px solid #ffffff" }}>
            <ModalBody>
              <div
                style={{
                  fontSize: 16,
                  color: "#ffffff",
                  fontFamily: "Inter",
                  fontWeight: 600,
                  lineHeight: "150%",
                  textAlign: "center",
                }}
              >
                {"Are you sure, you want to remove " +
                  selectedMemberEmail.email}
              </div>
            </ModalBody>
            <ModalFooter
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <div
                style={{ width: "60%" }}
                onClick={async () => {
                  await AuthService.getInstance().DeleteUserFromSpace(
                    selectedMemberEmail.id,
                    spaceDetails.id
                  );

                  toast({
                    title: selectedMemberEmail.email + " deleted successfully!",
                    status: "error",
                    isClosable: false,
                    position: "top",
                    duration: 2000,
                    id: "member-delete-success",
                  });

                  addUserClickedmainFunc(spaceDetails.members);

                  setModalOpen(false);
                }}
              >
                <MaterialButton variant={"contained"} label={"Confirm"} />
              </div>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {/* ----------------------Add User------------------------ */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          // onFocus={() => setMemberSearchBox(true)}
          // onBlur={() => {
          //   if (email.length === 0) {
          //     setMemberSearchBox(false);
          //   }
          // }}
          onChange={(e) => {
            setEmail(e.target.value.toLowerCase());
            setMemberSearchBox(true);
          }}
          type="email"
          value={email}
          placeholder="Enter Email"
          style={{
            padding: "8px 15px",
            borderRadius: 5,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            width: 600,
            fontSize: 16,
            fontFamily: "Inter",
            color: "#fff",
          }}
        />

        <div
          onClick={async () => {
            if (email.length > 0 && email === searchSelectedMember.email) {
              console.log(
                email,
                searchSelectedMember.email,
                searchSelectedMember.id,
                spaceDetails.id
              );

              await AuthService.getInstance().AddUserToSpace(
                searchSelectedMember.id,
                spaceDetails.id
              );

              toast({
                title: searchSelectedMember.email + " added successfully!",
                status: "success",
                isClosable: false,
                position: "top",
                duration: 2000,
                id: "member-add-success",
              });

              setEmail("");

              addUserClickedmainFunc(spaceDetails.members);
            }
          }}
          style={{ width: 130, marginLeft: 32 }}
        >
          <MaterialButton variant={"contained"} label={"Add User"} />
        </div>
      </div>

      {/* ----------------Search Box------------------- */}
      {email.length > 0 && memberSearchBox && (
        <div
          style={{
            width: 600,
            padding: "10px 0",
            backgroundColor: "rgba(0, 0, 0, 1)",
            position: "absolute",
            marginTop: 10,
            borderRadius: 5,
            height: 350,
            overflow: "scroll",
          }}
        >
          {searchMember.map((member, index) => (
            <ListItem
              onClick={() => {
                setMemberSearchBox(false);
                setEmail(member.email);
                setSearchSelectedMember(member);
              }}
              button
              key={index}
              style={{
                color: "#fff",
                width: "100%",
                padding: "10px 16px",
                fontSize: 16,
                fontFamily: "Inter",
                lineHeight: "150%",
                fontWeight: 400,
              }}
            >
              {member.email}
            </ListItem>
          ))}

          {searchMember.length === 0 && (
            <ListItem
              style={{
                color: "#fff",
                width: "100%",
                padding: "10px 16px",
                fontSize: 18,
                fontFamily: "Inter",
                lineHeight: "150%",
                fontWeight: 400,
                display: "flex",
                justifyContent: "center",
                marginTop: 24,
              }}
            >
              Please enter a valid Email address!
            </ListItem>
          )}
        </div>
      )}

      <Divider
        style={{ backgroundColor: "#89898B", width: "100%", marginTop: 21 }}
      />

      {/* --------------Space members--------------- */}
      <div style={{ overflow: "scroll", height: "90%" }}>
        {spaceDetails.members.map((member, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 25,
            }}
          >
            {/* -----------member detail------------ */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 43,
                  width: 43,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AvatarPreview
                  avatar={
                    member.avatarKey
                      ? avatars.get(member.avatarKey).img
                      : avartarLogo
                  }
                  bgColor="cld.fg3"
                />
              </div>

              <div style={{ marginLeft: 22 }}>
                <div
                  style={{
                    fontSize: 16,
                    fontFamily: "Inter",
                    fontWeight: 700,
                    color: "#fff",
                    lineHeight: "150%",
                  }}
                >
                  {member.playerName}
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "Inter",
                    fontWeight: 400,
                    color: "#fff",
                    lineHeight: "150%",
                  }}
                >
                  {member.email}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontFamily: "Inter",
                  fontWeight: 400,
                  color: "#fff",
                  lineHeight: "150%",
                  padding: "1px 0",
                  border: "1px solid #fff",
                  borderRadius: 5,
                  marginLeft: 8,
                  width: 65,
                  textAlign: "center",
                }}
              >
                {/* {member.role} */}

                {spaceDetails.admins.some((admin) => admin.id === member.id)
                  ? "admin"
                  : "user"}
              </div>

              {/* ---------Member settings Menu----------- */}
              <Menu>
                <MenuButton
                  style={{
                    marginLeft: 16,
                    cursor: "pointer",
                  }}
                >
                  <MoreVertIcon
                    style={{
                      color: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </MenuButton>
                <MenuList
                  style={{
                    backgroundColor: "#2C2C31",
                    border: "1px solid #C4C4C4",
                    marginLeft: -180,
                  }}
                >
                  <MenuItem
                    onClick={async () => {
                      console.log("member role update:", member.role);

                      if (
                        // spaceDetails.admins.some(
                        //   (admin) => admin.id === userDetails.id
                        // ) === true
                        userDetails.role === "admin" &&
                        spaceDetails.admins.some(
                          (admin) => admin.id === member.id
                        ) === false
                      ) {
                        await AuthService.getInstance().updateUserSpaceRole(
                          member.id,
                          spaceDetails.id
                        );

                        toast({
                          title: member.email + " is now an space admin!",
                          status: "success",
                          isClosable: false,
                          position: "top",
                          duration: 2000,
                          id: "role-update-success",
                        });

                        addUserClickedmainFunc(spaceDetails.members);
                      }
                      if (
                        // spaceDetails.admins.some(
                        //   (admin) => admin.id === userDetails.id
                        // ) === true

                        userDetails.role === "admin" &&
                        spaceDetails.admins.some(
                          (admin) => admin.id === member.id
                        ) === true
                      ) {
                        await AuthService.getInstance().removeuserAdminSpace(
                          member.id,
                          spaceDetails.id
                        );

                        toast({
                          title: member.email + " is now an space user!",
                          status: "success",
                          isClosable: false,
                          position: "top",
                          duration: 2000,
                          id: "role-update-success",
                        });

                        addUserClickedmainFunc(spaceDetails.members);
                      }
                      if (userDetails.role === "user") {
                        toast({
                          title: "You are not a system admin.",
                          status: "error",
                          isClosable: false,
                          position: "top",
                          duration: 2000,
                          id: "role-update-error",
                        });

                        addUserClickedmainFunc(spaceDetails.members);
                      }
                    }}
                    _active={{ bg: "rgba(0, 0, 0, 0.52)" }}
                    _focus={{ bg: "rgba(0, 0, 0, 0.52)" }}
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#FFFFFF",
                      lineHeight: "150%",
                      fontFamily: "Inter",
                    }}
                  >
                    {spaceDetails.admins.some((admin) => admin.id === member.id)
                      ? "Make User"
                      : "Make Admin"}
                  </MenuItem>
                  <MenuItem
                    _active={{ bg: "rgba(0, 0, 0, 0.52)" }}
                    _focus={{ bg: "rgba(0, 0, 0, 0.52)" }}
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#FFFFFF",
                      lineHeight: "150%",
                      fontFamily: "Inter",
                    }}
                    onClick={() => {
                      setModalOpen(true);
                      setSelectedMemberEmail(member);
                      console.log("member:", member);
                    }}
                  >
                    Remove Member
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpaceMembersList;
