import { useEffect, useState } from "react";
import AuthService from "../../services/AuthService";
import backgroundImage from "../../assets/background.jpg";
import SpacesGrid from "../../components/components/Dashboard/SpacesGrid";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  // ----------Avatar set check----------

  const userDetails = AuthService.getInstance().getUserDetails();

  // ------------Spaces API-------------
  const [createdSpaces, setCreatedSpaces] = useState([]);
  const [spacesLoading, setSpacesLoading] = useState(true);

  const loadDetails = async () => {
    var userDetails =
      await AuthService.getInstance().refreshUserDetailsFromRemoteAPI();
    console.log(userDetails);

    let spaceRequest = AuthService.getInstance().GetAllSpaces();
    var spaceDetails = await spaceRequest;
    setCreatedSpaces(spaceDetails.data.spaces);

    // if (spaceDetails.data.spaces.length == 1) {
    //   navigate("/" + spaceDetails.data.spaces[0].id);
    // }

    setSpacesLoading(false);
  };

  useEffect(() => {
    loadDetails();
  }, []);

  return (
    <div>
      <div
        style={{
          height: "100vh",
          width: "100%",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {spacesLoading ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(44, 44, 49, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                animationDuration: "550ms",
                color: "#ffffff",
              }}
              size={40}
              thickness={4}
            />
          </div>
        ) : (
          <SpacesGrid createdSpaces={createdSpaces} userDetails={userDetails} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
