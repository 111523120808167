import GoogleLogin from "react-google-login";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import loginStyle from "./LoginStyle";
import { useToast } from "@chakra-ui/react";
import AuthService from "../../services/AuthService";
import googleAuth from "../../assets/googleAuth.svg";

const useStyles = makeStyles(loginStyle);

const GoogleAuth = () => {
  const styles = useStyles();

  const toast = useToast();

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <GoogleLogin
        clientId="1021096195684-2mj1j3tmiug66qfv5jv2eaj454a2i703.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={async (response) => {
          console.log(response);
          console.log("Token is:", response.tokenId);

          try {
            await AuthService.getInstance().googleAuth(response.tokenId);

            window.location.reload();
          } catch (error) {
            console.log(error);
          }
        }}
        onFailure={(error) => {
          console.log(error);
          if (error && error.error) {
            toast({
              title: error.error,
              status: "error",
              isClosable: false,
              position: "top",
              duration: 2000,
              id: "google-auth-error",
            });
          }
          setLoading(false);
        }}
        cookiePolicy={"single_host_origin"}
        render={(renderProps) => (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              marginTop: 15,
            }}
          >
            <Button
              onClick={() => {
                setLoading(true);
                renderProps.onClick();
              }}
              className={
                loading
                  ? styles.googleAuthButtonLoading
                  : styles.googleAuthButton
              }
              variant="outlined"
              color="secondary"
            >
              {!loading && <img src={googleAuth} alt="googleAuth" />}
              <div>Login with Google</div>
            </Button>

            {loading && (
              <CircularProgress
                size={24}
                style={{
                  color: "#ffffff",
                  position: "absolute",
                  top: "25%",
                  left: "48%",
                }}
              />
            )}
          </div>
        )}
      />
    </div>
  );
};

export default GoogleAuth;
