import errorImage from "../../assets/errorImage.png";
import MaterialButton from "../../components/components/Button/Button";

const NotFound = () => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100vh",
        backgroundColor: "#2c2c31",
        overflow: "scroll",
      }}
    >
      <div
        style={{
          position: "relative",
          maxWidth: 1120,
          margin: "100px auto",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 220px)",
          }}
        >
          <img src={errorImage} alt="errorImage" />

          <h2
            style={{
              color: "#787DF9",
              fontWeight: 700,
              fontSize: 80,
              fontFamily: "Inter",
              marginTop: 40,
            }}
          >
            Oops!
          </h2>

          <div
            style={{
              color: "#ffffff",
              fontWeight: 700,
              fontSize: 36,
              fontFamily: "Inter",
              marginTop: 35,
              textAlign: "center",
            }}
          >
            Error 404- Page Not Found
          </div>

          <div
            style={{
              color: "#ffffff",
              fontWeight: 400,
              fontSize: 24,
              fontFamily: "Inter",
              marginTop: 35,
              textAlign: "center",
            }}
          >
            The page your requested couldn’t be found. We’re trying to fix it.
          </div>

          <div
            style={{ width: 200, marginTop: 40 }}
            onClick={() => window.location.replace("/")}
          >
            <MaterialButton variant={"contained"} label={"Return to Spaces"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
