import { useEffect, useState } from "react";
import MaterialButton from "../Button/Button";
import Divider from "@mui/material/Divider";
import clientLogo from "../../../assets/so.png";
import { makeStyles } from "@material-ui/core";
import { useToast } from "@chakra-ui/react";
import AuthService from "../../../services/AuthService";
import dashboardStyles from "./dashboardStyles";
import Chip from "@mui/material/Chip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyles = makeStyles(dashboardStyles);

const SpaceSettings = ({ spaceDetails, spaceEditedFunc }) => {
  const styles = useStyles();
  const toast = useToast();

  const [editClicked, setEditClicked] = useState(false);

  const [spaceName, setSpaceName] = useState(spaceDetails.name);
  const [spaceDescription, setSpaceDescription] = useState(
    spaceDetails.description
  );

  // ----------------Domain select------------------
  const [domainNamesArray, setDomainNamesArray] = useState(
    spaceDetails.domains
  );
  const [domainName, setDomainName] = useState("");

  useEffect(() => {
    console.log("domainNamesArray", domainNamesArray);
  }, [domainNamesArray]);

  const handleDomainDelete = (index) => {
    const removeItem = domainNamesArray.filter((domain) => {
      return domain !== domainNamesArray[index];
    });
    setDomainNamesArray(removeItem);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {/* -----------Header buttons---------- */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {!editClicked && (
          <div
            onClick={() => setEditClicked(true)}
            style={{ height: 48, width: 130 }}
          >
            <MaterialButton variant={"contained"} label={"Edit"} />
          </div>
        )}

        {editClicked && (
          <div style={{ display: "flex" }}>
            <div
              onClick={async () => {
                try {
                  await AuthService.getInstance().EditSpace(
                    spaceName,
                    domainNamesArray,
                    spaceDescription,
                    spaceDetails.id
                  );
                  toast({
                    title: spaceName + " edited successfully!",
                    status: "success",
                    isClosable: false,
                    position: "top",
                    duration: 2000,
                    id: "space-error",
                  });

                  spaceEditedFunc(spaceName);

                  setEditClicked(false);
                } catch (error) {
                  if (error.response.data && error.response.data.error) {
                    toast({
                      title: error.response.data.error,
                      status: "error",
                      isClosable: false,
                      position: "top",
                      duration: 2000,
                      id: "space-error",
                    });
                  }
                }
              }}
              style={{ height: 48, width: 156 }}
            >
              <MaterialButton variant={"contained"} label={"Save Changes"} />
            </div>

            <div
              onClick={() => {
                setEditClicked(false);
                setDomainNamesArray(spaceDetails.domains);
              }}
              style={{ height: 48, width: 130, marginLeft: 26 }}
            >
              <MaterialButton variant={"outlined"} label={"Discard"} />
            </div>
          </div>
        )}
      </div>

      <Divider style={{ backgroundColor: "#89898B", marginTop: 20 }} />

      {/* ----------Space details------------ */}
      <div style={{ marginTop: 40 }}>
        <img src={clientLogo} alt="Client_logo" />

        {/* -------------name input--------- */}
        <div style={{ width: 350, marginTop: 40 }}>
          <div
            style={{
              color: "#fff",
              fontSize: 16,
              fontFamily: "Inter",
              fontWeight: 300,
              lineHeight: "150%",
            }}
          >
            Space Name
          </div>

          <input
            disabled={!editClicked}
            id="name"
            className={styles.textField}
            type="text"
            value={spaceName}
            onChange={(e) => setSpaceName(e.target.value)}
          />
        </div>

        {/* -------------------Domain Array------------------ */}
        <div>
          <div
            style={{
              marginTop: 30,
              fontSize: 16,
              fontFamily: "Inter",
              fontWeight: 300,
              lineHeight: "150%",
              color: "#EDEDED",
            }}
          >
            {editClicked ? "Enter domains" : "Domains"}
          </div>

          {editClicked && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="text"
                id="domainName"
                style={{
                  width: 310,
                  marginTop: 10,
                  backgroundColor: "rgba(0, 0, 0, 0.43)",
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: 400,
                  lineHeight: "150%",
                  color: "#FFFFFF",
                  padding: 9,
                  borderRadius: 5,
                }}
                placeholder="Enter domain Name"
                value={domainName}
                onChange={(e) => setDomainName(e.target.value)}
              />

              <div
                onClick={() => {
                  if (
                    domainName.length > 0 &&
                    domainNamesArray.includes(domainName) === false
                  ) {
                    domainNamesArray.push(domainName);
                    setDomainName("");
                  }
                }}
                style={{
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: 700,
                  lineHeight: "150%",
                  color: "#ffffff",
                  cursor: "pointer",
                  marginTop: 8,
                  marginLeft: 8,
                }}
              >
                <MaterialButton variant={"outlined"} label={"Add"} />
              </div>
            </div>
          )}

          {domainNamesArray.length > 0 ? (
            <div style={editClicked ? { marginTop: 10 } : { margin: 0 }}>
              {domainNamesArray.map((domain, index) => (
                <Chip
                  label={domain}
                  variant="outlined"
                  onDelete={() => {
                    if (editClicked) {
                      handleDomainDelete(index);
                    }
                  }}
                  key={index}
                  style={{
                    color: "#ffffff",
                    marginRight: 5,
                    fontFamily: "Inter",
                    lineHeight: "150%",
                    fontWeight: 400,
                    marginTop: 8,
                  }}
                  deleteIcon={
                    editClicked ? (
                      <HighlightOffIcon
                        style={{
                          color: "#ffffff",
                          transform: "scale(0.8)",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          display: "none",
                        }}
                      />
                    )
                  }
                />
              ))}
            </div>
          ) : (
            <div
              style={{
                color: "#ffffff",
                fontFamily: "Inter",
                lineHeight: "150%",
                fontWeight: 400,
                marginTop: 8,
                fontSize: 16,
              }}
            >
              {editClicked ? "" : "No domains added."}
            </div>
          )}
        </div>

        {/* --------Space description---------- */}
        <div style={{ width: 350, marginTop: 25 }}>
          {/* [input] */}
          <div
            style={{
              color: "#fff",
              fontSize: 16,
              fontFamily: "Inter",
              fontWeight: 300,
              lineHeight: "150%",
              marginTop: 20,
            }}
          >
            Space Description
          </div>
          <input
            disabled={!editClicked}
            id="description"
            className={styles.textField}
            type="text"
            value={spaceDescription}
            onChange={(e) => setSpaceDescription(e.target.value)}
          />
        </div>

        {/* {editClicked && (
          <div style={{ marginTop: 40, width: 162 }}>
            <Button variant="contained" className={styles.button}>
              Delete Space
            </Button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SpaceSettings;
