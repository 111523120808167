import { makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik } from "formik";
import AuthService from "../../services/AuthService";
import resetPassStyle from "./ResetPassStyle";
import { useToast } from "@chakra-ui/react";
import SOImage from "../../assets/so.png";

const useStyles = makeStyles(resetPassStyle);

const EmailVerification = () => {
  const styles = useStyles();
  const toast = useToast();

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {/* ---------------------Card------------------- */}
        <div className={styles.emailCardContainer}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img src={SOImage} alt="logo" />
          </div>

          <div
            style={{
              color: "#fff",
              fontSize: "1rem ",
              fontFamily: "Inter ",
              fontWeight: "400 ",
              lineHeight: "150% ",
              marginBottom: 20,
              textAlign: "center",
              marginTop: 30,
            }}
          >
            Enter email address associated with your account and we'll send you
            a link to reset your password.
          </div>

          {/* ------------------Form-------------- */}

          <Formik
            initialValues={{ email: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await AuthService.getInstance().forgotPassword(values.email);

                toast({
                  title: "Please check your email!",
                  status: "success",
                  isClosable: false,
                  position: "top",
                  duration: 2000,
                  id: "email-success",
                });
              } catch (error) {
                if (error.response.data && error.response.data.error) {
                  toast({
                    title: error.response.data.error,
                    status: "error",
                    isClosable: false,
                    position: "top",
                    duration: 2000,
                    id: "email-error",
                  });
                }
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  size="small"
                  error={errors.email && touched.email && errors.email}
                  helperText={errors.email && touched.email && errors.email}
                  label="Email"
                  id="email"
                  variant="outlined"
                  margin="normal"
                  className={styles.textField}
                  InputProps={{
                    style: {
                      color: "#ffffff ",
                      width: "100% ",
                      fontSize: 16,
                    },
                  }}
                  InputLabelProps={{
                    style:
                      errors.email && touched.email && errors.email
                        ? { color: "red", fontSize: 16 }
                        : { color: "#ffffff", fontSize: 16 },
                  }}
                  type="email"
                  onChange={handleChange}
                  value={values.email}
                />

                <div style={{ position: "relative" }}>
                  <Button
                    type="submit"
                    className={
                      isSubmitting
                        ? styles.confirmButtonLoading
                        : styles.confirmButton
                    }
                    variant="contained"
                  >
                    Continue
                  </Button>

                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "#000",
                        position: "absolute",
                        top: "45%",
                        left: "45%",
                      }}
                    />
                  )}
                </div>
              </form>
            )}
          </Formik>

          <div className={styles.getStarted}>
            <Link to="/" style={{ textDecoration: "none" }}>
              Go back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
