import { makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik } from "formik";
import resetPassStyle from "./ResetPassStyle";
import { useToast } from "@chakra-ui/react";
import AuthService from "../../services/AuthService";
import SOImage from "../../assets/so.png";

const useStyles = makeStyles(resetPassStyle);

const ResetPass = () => {
  const styles = useStyles();

  const toast = useToast();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      console.log("token:", token);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {/* ---------------------Card------------------- */}

        <div className={styles.cardContainer}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: 30,
            }}
          >
            <img src={SOImage} alt="logo" />
          </div>

          {/* ----------------Form--------------- */}
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validate={(values) => {
              const errors = {};

              if (values.password !== values.confirmPassword) {
                errors.password = "Passwords do not match";
                errors.confirmPassword = "Passwords do not match";
              } else if (
                values.password.length < 8 ||
                values.confirmPassword.length < 8
              ) {
                errors.password = "Enter at least 8 characters";
                errors.confirmPassword = "Enter at least 8 characters";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await AuthService.getInstance().resetPassword(
                  token,
                  values.password
                );

                toast({
                  title: "Password changed successfully!",
                  status: "success",
                  isClosable: false,
                  position: "top",
                  duration: 2000,
                  id: "pass-reset-success",
                });

                setTimeout(() => {
                  window.location.replace("/");
                }, 1000);
              } catch (error) {
                if (error.response.data && error.response.data.error) {
                  toast({
                    title: error.response.data.error,
                    status: "error",
                    isClosable: false,
                    position: "top",
                    duration: 2000,
                    id: "pass-reset-error",
                  });
                }
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  size="small"
                  fullWidth
                  error={errors.password && touched.password && errors.password}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                  label="New Password"
                  id="password"
                  variant="outlined"
                  margin="normal"
                  className={styles.textField}
                  InputProps={{
                    style: {
                      color: "#ffffff ",
                      width: "100% ",
                      fontSize: 16,
                    },
                  }}
                  InputLabelProps={{
                    style:
                      errors.password && touched.password && errors.password
                        ? { color: "red", fontSize: 16 }
                        : { color: "#ffffff", fontSize: 16 },
                  }}
                  type="password"
                  onChange={handleChange}
                  value={values.password}
                />

                <TextField
                  size="small"
                  fullWidth
                  error={
                    errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword
                  }
                  helperText={
                    errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword
                  }
                  label="Confirm New Password"
                  id="confirmPassword"
                  variant="outlined"
                  margin="normal"
                  className={styles.textField}
                  InputProps={{
                    style: {
                      color: "#ffffff ",
                      width: "100% ",
                      fontSize: 16,
                    },
                  }}
                  InputLabelProps={{
                    style:
                      errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword
                        ? { color: "red", fontSize: 16 }
                        : { color: "#ffffff", fontSize: 16 },
                  }}
                  type="password"
                  onChange={handleChange}
                  value={values.confirmPassword}
                />

                <div style={{ position: "relative" }}>
                  <Button
                    type="submit"
                    className={
                      isSubmitting
                        ? styles.confirmButtonLoading
                        : styles.confirmButton
                    }
                    variant="contained"
                  >
                    Confirm
                  </Button>

                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "#000",
                        position: "absolute",
                        top: "40%",
                        left: "48%",
                      }}
                    />
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
