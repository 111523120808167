import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthService from "./services/AuthService";
import Login from "./pages/Login/Login";

import { Box, Flex, Spinner } from "@chakra-ui/react";
import ResetPass from "./pages/ResetPassword/ResetPass";
import EmailVerification from "./pages/ResetPassword/EmailVerification";
import NotFound from "./pages/NotFound/NotFound";
import Dashboard from "./pages/Dashboard/Dashboard";
import CreateSpace from "./pages/CreateSpace/CreateSpace";
import SpaceDetails from "./components/components/Dashboard/SpaceDetails";

const GameLoader = React.lazy(() => import("./GameLoader"));

export default function Router() {
  const isAuthenticated = AuthService.getInstance().isAuthenticated();
  const userDetails = AuthService.getInstance().getUserDetails();

  if (!isAuthenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<ResetPass />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <Suspense
      fallback={
        <Box pos="absolute" w="100%" h="100%">
          <Flex minH={"100vh"} align={"center"} justify={"center"}>
            <Spinner />
          </Flex>
        </Box>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />

          {userDetails.role === "admin" && (
            <Route path="/space/create" element={<CreateSpace />} />
          )}

          <Route path="/space/edit/:id/" element={<SpaceDetails />} />

          <Route path="/:id" element={<GameLoader />} />
          <Route path="/error" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
