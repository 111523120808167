const createSpaceStyle = (theme) => ({
  textField: {
    width: "100% !important",
    backgroundColor: "rgba(0, 0, 0, 0.43) !important",
    fontFamily: "Inter !important",
    fontWeight: 400,
    lineHeight: "150% !important",
    color: "#FFFFFF !important",
    borderRadius: "5px !important",
    marginTop: "10px !important",

    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff ",
        borderRadius: "10px !important",
      },
    },
  },
});

export default createSpaceStyle;
