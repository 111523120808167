import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import spaceImage from "../../assets/so_logo.png";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core";
import createSpaceStyle from "./createSpaceStyle";
import { useEffect, useState } from "react";
import AuthService from "../../services/AuthService";
import { useToast } from "@chakra-ui/react";
import background from "../../assets/background.jpg";
import MaterialButton from "./../../components/components/Button/Button";
import Chip from "@mui/material/Chip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyles = makeStyles(createSpaceStyle);

const CreateSpace = () => {
  const styles = useStyles();

  const navigate = useNavigate();
  const toast = useToast();

  const [spaceName, setSpaceName] = useState("");
  const [spaceDescription, setSpaceDescription] = useState("");

  // ----------------Domain select------------------
  const [domainNamesArray, setDomainNamesArray] = useState([]);
  const [domainName, setDomainName] = useState("");

  useEffect(() => {
    console.log("domainNamesArray", domainNamesArray);
  }, [domainNamesArray]);

  const handleDomainDelete = (index) => {
    const removeItem = domainNamesArray.filter((domain) => {
      return domain !== domainNamesArray[index];
    });
    setDomainNamesArray(removeItem);
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        backgroundImage: "url(" + background + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: "70px 30px 15px 30px",
          backgroundColor: "rgba(44, 44, 49, 0.8)",
          overflow: "scroll",
        }}
      >
        <div style={{ maxWidth: "80%", margin: "0 auto" }}>
          {/* ----------Selected space------------ */}
          <div>
            <div
              style={{
                fontSize: 24,
                fontFamily: "Inter",
                fontWeight: 700,
                lineHeight: "150%",
                color: "#ffffff",
              }}
            >
              Setting Up Your New Virtual Office
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                marginTop: 40,
              }}
            >
              <div
                style={{
                  height: 250,
                  width: 430,
                  backgroundColor: "rgba(44, 44, 49, 0.8)",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={spaceImage}
                  alt="spaceImage"
                  style={{ width: 100, height: 100 }}
                />
              </div>

              <div
                style={{
                  width: 430,
                  marginTop: 30,
                  backgroundColor: "rgba(44, 44, 49, 0.8)",
                  borderRadius: 10,
                  padding: "24px 34px",
                }}
              >
                <div>
                  <div
                    style={{
                      marginTop: 20,
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: 300,
                      lineHeight: "150%",
                      color: "#EDEDED",
                    }}
                  >
                    Space Name
                  </div>
                  <input
                    type="text"
                    id="spaceName"
                    style={{
                      width: "100%",
                      marginTop: 10,
                      backgroundColor: "rgba(0, 0, 0, 0.43)",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: 400,
                      lineHeight: "150%",
                      color: "#FFFFFF",
                      padding: 9,
                      borderRadius: 5,
                    }}
                    placeholder="Office 2.0"
                    onChange={(e) => setSpaceName(e.target.value)}
                  />
                </div>

                {/* -------------------Domain Array------------------ */}
                <div>
                  <div
                    style={{
                      marginTop: 30,
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: 300,
                      lineHeight: "150%",
                      color: "#EDEDED",
                    }}
                  >
                    {"Enter domains (optional)"}
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      id="domainName"
                      style={{
                        width: "100%",
                        marginTop: 10,
                        backgroundColor: "rgba(0, 0, 0, 0.43)",
                        fontSize: 16,
                        fontFamily: "Inter",
                        fontWeight: 400,
                        lineHeight: "150%",
                        color: "#FFFFFF",
                        padding: 9,
                        borderRadius: 5,
                      }}
                      placeholder="Enter domain Name"
                      value={domainName}
                      onChange={(e) => setDomainName(e.target.value)}
                    />

                    <div
                      onClick={() => {
                        if (
                          domainName.length > 0 &&
                          domainNamesArray.includes(domainName) === false
                        ) {
                          domainNamesArray.push(domainName);
                          setDomainName("");
                        }
                      }}
                      style={{
                        fontSize: 16,
                        fontFamily: "Inter",
                        fontWeight: 700,
                        lineHeight: "150%",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginTop: 8,
                        marginLeft: 8,
                      }}
                    >
                      <MaterialButton variant={"outlined"} label={"Add"} />
                    </div>
                  </div>

                  <div style={{ marginTop: 10 }}>
                    {domainNamesArray.map((domain, index) => (
                      <Chip
                        label={domain}
                        variant="outlined"
                        onDelete={() => handleDomainDelete(index)}
                        key={index}
                        style={{
                          color: "#ffffff",
                          marginRight: 5,
                          fontFamily: "Inter",
                          lineHeight: "150%",
                          fontWeight: 400,
                          marginTop: 8,
                        }}
                        deleteIcon={
                          <HighlightOffIcon
                            style={{
                              color: "#ffffff",
                              transform: "scale(0.8)",
                            }}
                          />
                        }
                      />
                    ))}
                  </div>
                </div>

                {/* ---------------Space description----------------- */}
                <div>
                  <div
                    style={{
                      marginTop: 30,
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: 300,
                      lineHeight: "150%",
                      color: "#EDEDED",
                    }}
                  >
                    Add Description
                  </div>
                  <TextField
                    id="spaceDescription"
                    margin="normal"
                    fullWidth
                    className={styles.textField}
                    InputProps={{
                      style: {
                        color: "#ffffff ",
                        width: "100% ",
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: "#ffffff", fontSize: 16 },
                    }}
                    multiline
                    onChange={(e) => setSpaceDescription(e.target.value)}
                  />
                </div>

                {/* ------------------Finish button---------------- */}
                <div
                  style={{ marginTop: 12 }}
                  onClick={async () => {
                    try {
                      await AuthService.getInstance().CreateSpace(
                        spaceName,
                        domainNamesArray,
                        spaceDescription
                      );
                      console.log(spaceName, spaceDescription);
                    } catch (error) {
                      if (error.response.data && error.response.data.error) {
                        toast({
                          title: error.response.data.error,
                          status: "error",
                          isClosable: false,
                          position: "top",
                          duration: 2000,
                          id: "space-error",
                        });
                      }
                    }
                  }}
                >
                  <MaterialButton variant={"contained"} label={"Finish"} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ArrowBackIosIcon
          onClick={() => navigate(-1)}
          style={{
            color: "#ffffff",
            transform: "scale(1.2)",
            position: "absolute",
            top: 74,
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

export default CreateSpace;

// ----------------Create space options---------------
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import { Link, useNavigate } from "react-router-dom";
// import spaceImage from "../../assets/so_logo.png";
// import { Grid } from "@mui/material";
// import TextField from "@mui/material/TextField";
// import { makeStyles } from "@material-ui/core";
// import loginStyle from "../Login/LoginStyle";
// import createSpaceStyle from "./createSpaceStyle";
// import MaterialButton from "../../components/Button/Button";
// import CreateSpaceDetails from "./CreateSpaceDetails";
// import background from "../../assets/background.png";

// const spaces = [
//   {
//     id: "0",
//     spaceImage: spaceImage,
//     spaceName: "Space 1",
//   },

// ];

// const useStyles = makeStyles(createSpaceStyle);

// const CreateSpace = () => {
//   const styles = useStyles();

//   const navigate = useNavigate();

//   return (
//     <div
//       style={{
//         position: "absolute",
//         width: "100%",
//         height: "100vh",
//         backgroundImage: "url(" + background + ")",
//       }}
//     >
//       <div
//         style={{
//           width: "100%",
//           height: "100%",
//           padding: "70px 30px 15px 30px",
//           overflow: "scroll",
//           backgroundColor: "rgba(44, 44, 49, 0.8)",
//         }}
//       >
//         <div style={{ maxWidth: "80%", margin: "0 auto" }}>
//           {/* -----------Prebuilt spaces------------- */}
//           <div>
//             <div
//               style={{
//                 fontSize: 24,
//                 fontFamily: "Inter",
//                 fontWeight: 700,
//                 lineHeight: "150%",
//                 color: "#ffffff",
//               }}
//             >
//               Choose a Space For Your New Virtual Office
//             </div>

//             <Grid container style={{ marginTop: 80 }} spacing={6}>
//               {spaces.map((space, index) => (
//                 <Grid item xs={12} sm={3} key={space.id}>
//                   <Link to="/create-space/selected-space">
//                     <div
//                       style={{
//                         height: 187,
//                         width: "100%",
//                         backgroundColor: "rgba(44, 44, 49, 0.8)",
//                         borderRadius: 5,
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         cursor: "pointer",
//                       }}
//                     >
//                       <img
//                         src={space.spaceImage}
//                         alt="spaceImage"
//                         style={{ width: 100, height: 100 }}
//                       />
//                     </div>
//                   </Link>
//                 </Grid>
//               ))}
//             </Grid>
//           </div>
//         </div>

//         <ArrowBackIosIcon
//           onClick={() => navigate(-1)}
//           style={{
//             color: "#ffffff",
//             transform: "scale(1.2)",
//             position: "absolute",
//             top: 74,
//             cursor: "pointer",
//           }}
//         />
//       </div>
//     </div>
//   );
// };

// export default CreateSpace;
