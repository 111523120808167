import backgroundImage from "../../assets/background.jpg";

const resetPassStyle = (theme) => ({
  container: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  emailCardContainer: {
    width: 416,
    background: "rgba(44, 44, 49, 0.9)",
    borderRadius: "16px",
    padding: 36,
    boxShadow: "0px 0px 5px #0000006f",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  cardContainer: {
    width: 416,
    background: "rgba(44, 44, 49, 0.9)",
    borderRadius: "16px",
    padding: "36px 60px",
    boxShadow: "0px 0px 5px #0000006f",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  textField: {
    color: "#ffffff !important",

    "& label.Mui-focused": {
      color: "#ffffff ",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ffffff ",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff ",
      },
      "&:hover fieldset": {
        borderColor: "#ffffff ",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff ",
      },
    },
  },

  confirmButton: {
    width: "100%",
    height: "40px !important",
    textTransform: "none !important",
    color: "#ffffff !important",
    fontSize: "1rem !important",
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    lineHeight: "150% !important",
    marginTop: "15px !important",
    background: "#787DF9 !important",
  },

  confirmButtonLoading: {
    width: "100%",
    height: "40px !important",
    textTransform: "none !important",
    color: "#787DF9 !important",
    fontSize: "1rem !important",
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    lineHeight: "150% !important",
    marginTop: "15px !important",
    background: "#787DF9 !important",
  },

  signUpText: {
    fontSize: "1rem",
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "150%",
    color: "#ffffff",
    marginTop: 10,
  },

  getStarted: {
    cursor: "pointer",
    fontSize: "1rem",
    fontFamily: "Inter",
    fontWeight: 600,
    lineHeight: "150%",
    color: "#ffffff",
    marginTop: 40,
    textAlign: "center",
    width: "100%",
  },

  image: {
    height: "100%",
    width: "100%",

    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
    },
  },
});

export default resetPassStyle;
