import Adam from "../assets/Adam_login.png";
import Ash from "../assets/Ash_login.png";
import Lucy from "../assets/Lucy_login.png";
import Nancy from "../assets/Nancy_login.png";

export const avatars = new Map([
  ["adam", { name: "adam", img: Adam }],
  ["ash", { name: "ash", img: Ash }],
  ["lucy", { name: "lucy", img: Lucy }],
  ["nancy", { name: "nancy", img: Nancy }],
]);

export const avatarNames = [...avatars.keys()];