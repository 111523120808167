import buttonStyle from "./ButtonStyle";
import { makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";

const useStyles = makeStyles(buttonStyle);

const MaterialButton = ({ variant, label }) => {
  const styles = useStyles();

  return (
    <Button
      variant={variant}
      className={
        variant === "contained" ? styles.buttonContained : styles.buttonOutlined
      }
    >
      {label}
    </Button>
  );
};

export default MaterialButton;
