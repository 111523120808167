import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
// import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { ChakraProvider } from "@chakra-ui/react";
import theme from "@livekit/livekit-chakra-theme";

import "./index.css";
// import store from "./stores";
import Router from "./Router";

require("dotenv").config();

Sentry.init({
  dsn: "https://bf14e33d56c342019290bb13765de60e@o1169181.ingest.sentry.io/6261747",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Router />
      {/* <Provider store={store}>
        <Router />
      </Provider> */}
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
