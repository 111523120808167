import axios from "axios";

// base url for the api
// export const API_URL = "http://localhost:8090/api/";

const API_URL = process.env.REACT_APP_SERVER_URL + "/api/"; //`https://apiv1.jobrank.co/api/`;

// auth service
export default class AuthService {
  // singleton instance
  private static instance: AuthService;

  // private constructor
  private constructor() {}

  // get instance
  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  // login
  public login(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "auth/login", {
          email,
          password,
        })
        .then((response) => {
          if (
            response.data.token &&
            response.data.refreshToken &&
            response.data.user
          ) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem(
              "currentUser",
              JSON.stringify(response.data.user)
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //Google auth
  public googleAuth(token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "auth/google", {
          id_token: token,
        })
        .then((response) => {
          if (
            response.data.token &&
            response.data.refreshToken &&
            response.data.user
          ) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem(
              "currentUser",
              JSON.stringify(response.data.user)
            );
          }

          window.location.reload();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // register
  public register(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "auth/register", {
          email,
          password,
        })
        .then((response) => {
          if (
            response.data.token &&
            response.data.refreshToken &&
            response.data.user
          ) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem(
              "currentUser",
              JSON.stringify(response.data.user)
            );
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // forgot password
  public forgotPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "auth/forgotPassword", {
          email,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // reset password
  public resetPassword(token: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "auth/resetPassword", {
          token,
          password,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateUser(playerName: string, avatarKey: string) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "account",
          {
            playerName,
            avatarKey,
          },
          {
            headers: {
              Authorization: `Bearer ${this.getToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.data && response.data.user) {
            localStorage.setItem(
              "currentUser",
              JSON.stringify(response.data.user)
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public async refreshUserDetailsFromRemoteAPI() {
    try {
      const response = await axios.get(API_URL + "account", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.user) {
        localStorage.setItem("currentUser", JSON.stringify(response.data.user));
      }
      return response;
    } catch (error: any) {
      if (
        error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.status === 404
      ) {
        this.logout();
        window.location.reload();
      }
      throw error;
    }
  }

  //Create space
  public CreateSpace(name: string, domains: any, description: string) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "spaces",
          {
            name,
            domains,
            description,
          },
          {
            headers: {
              Authorization: `Bearer ${this.getToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response);
          console.log(response);
          window.location.replace("/" + response.data.space.id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //All Spaces
  public GetAllSpaces() {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + "spaces", {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response);
          console.log("Getallspaces:", response);
        })
        .catch((error) => {
          reject(error);
          console.log("Getallspaces:", error);
        });
    });
  }

  //Space details
  public GetSpaceDetail(id: any) {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + "spaces/" + id, {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response);
          console.log("GetSpaceDetail: ", response);
        })
        .catch((error) => {
          reject(error);
          console.log("GetSpaceDetail: ", error);
        });
    });
  }

  //Edit space
  public EditSpace(
    name: string,
    domains: any,
    description: string,
    id: string
  ) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          API_URL + "spaces/" + id,
          {
            name,
            domains,
            description,
          },
          {
            headers: {
              Authorization: `Bearer ${this.getToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response);
          console.log("Edit space response:", response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //Get all users
  public GetAllUsers() {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + "admin/users", {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response);
          console.log("GetAllUsers: ", response);
        })
        .catch((error) => {
          reject(error);
          console.log("GetAllUsers error: ", error.response);
        });
    });
  }

  //Add user
  public AddUserToSpace(userId: string, spaceId: string) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "spaces/" + spaceId + "/members/",
          {
            userId,
          },
          {
            headers: {
              Authorization: `Bearer ${this.getToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response);
          console.log("Add member", response);
        })
        .catch((error) => {
          reject(error);
          console.log("Add member error", error);
        });
    });
  }

  //Make user admin
  public UpdateUserRole(userId: string, userRole: string) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "admin/users/" + userId + "/role",
          {
            role: userRole,
          },
          {
            headers: {
              Authorization: `Bearer ${this.getToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response);
          console.log("Update role", response);
        })
        .catch((error) => {
          reject(error);
          console.log("Update role error", error);
        });
    });
  }

  //Delete user
  public DeleteUserFromSpace(userId: string, spaceId: string) {
    return new Promise((resolve, reject) => {
      axios
        .delete(API_URL + "spaces/" + spaceId + "/members/", {
          data: {
            userId,
          },
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response);
          console.log("Delete member", response);
        })
        .catch((error) => {
          reject(error);
          console.log("Delete member error", error);
        });
    });
  }

  // ------------------------Space--------------------------
  //Make user space admin
  public updateUserSpaceRole(userId: string, spaceId: string) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "spaces/" + spaceId + "/admins",
          {
            userId,
          },
          {
            headers: {
              Authorization: `Bearer ${this.getToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response);
          console.log("updateUserSpaceRole", response);
        })
        .catch((error) => {
          reject(error);
          console.log("updateUserSpaceRole error", error);
        });
    });
  }

  //Delete space user
  public removeuserAdminSpace(userId: string, spaceId: string) {
    return new Promise((resolve, reject) => {
      axios
        .delete(API_URL + "spaces/" + spaceId + "/admins", {
          data: {
            userId,
          },
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response);
          console.log("removeuserAdminSpace", response);
        })
        .catch((error) => {
          reject(error);
          console.log("removeuserAdminSpace error", error);
        });
    });
  }

  // logout
  public logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("currentUser");
  }

  // check if user is logged in or not
  public isAuthenticated(): boolean {
    if (localStorage.getItem("token") && localStorage.getItem("refreshToken")) {
      return true;
    } else {
      return false;
    }
  }

  // get auth token
  public getToken(): string {
    return localStorage.getItem("token") || "";
  }

  public getUserDetails(): any {
    const user = localStorage.getItem("currentUser");
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  }
}
