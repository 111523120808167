import backgroundImage from "../../assets/background.jpg";

const loginStyle = (theme) => ({
  container: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  contentContainer: {
    background: "rgba(44, 44, 49, 0.9)",
    borderRadius: "16px",
    padding: 36,
    boxShadow: "0px 0px 5px #0000006f",
    width: 416,
    height: 500,
  },

  titleText: {
    fontSize: "1.75rem",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "150%",
    color: "#ffffff",
    textAlign: "center",
  },

  contentGridContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },

  textField: {
    color: "#ffffff !important",

    "& label.Mui-focused": {
      color: "#ffffff ",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ffffff ",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff ",
      },
      "&:hover fieldset": {
        borderColor: "#ffffff ",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff ",
      },
    },
  },

  loginButton: {
    width: "100%",
    height: "40px !important",
    textTransform: "none !important",
    color: "#ffffff !important",
    fontSize: "1rem !important",
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    lineHeight: "150% !important",
    marginTop: "15px !important",
    background: "#787DF9 !important",
  },

  loginButtonLoading: {
    width: "100%",
    height: "40px !important",
    textTransform: "none !important",
    color: "#787DF9 !important",
    fontSize: "1rem !important",
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    lineHeight: "150% !important",
    marginTop: "15px !important",
    background: "#787DF9 !important",
  },

  googleAuthButton: {
    width: "70%",
    display: "flex !important",
    justifyContent: "space-between !important",
    padding: "0 25px !important",
    alignItems: "center !important",
    height: "45px !important",
    textTransform: "none !important",
    color: "#ffffff !important",
    fontSize: "1rem !important",
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    lineHeight: "150% !important",
    border: "1px solid #ffffff !important",
  },

  googleAuthButtonLoading: {
    width: "70%",
    display: "flex !important",
    justifyContent: "space-between !important",
    padding: "0 25px !important",
    alignItems: "center !important",
    height: "45px !important",
    textTransform: "none !important",
    color: "rgba(44, 44, 49, 0) !important",
    fontSize: "1rem !important",
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    lineHeight: "150% !important",
    border: "1px solid #ffffff !important",
  },

  orText: {
    fontSize: "1rem",
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "150%",
    color: "#ffffff",
    marginTop: 15,
    textAlign: "center",
  },

  signUpText: {
    fontSize: "1rem",
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "150%",
    color: "#ffffff",
    marginTop: 10,
  },

  getStarted: {
    cursor: "pointer",
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 600,
    lineHeight: "150%",
  },
});

export default loginStyle;
