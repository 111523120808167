import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import ListItem from "@mui/material/ListItem";
import { useEffect, useState } from "react";
import AuthService from "../../../services/AuthService";
import SpaceMembersList from "./SpaceMembersList";
import SpaceSettings from "./SpaceSettings";

const SpaceDetailsSidebar = ({
  spaceDetails,
  addUserClickedmainFunc,
  spaceEditedFunc,
}) => {
  const [activeOption, setActiveOption] = useState(1);
  // const [addUserClicked, setAddUserClicked] = useState(false);

  const userDetails = AuthService.getInstance().getUserDetails();

  // ------------All Members list-------------
  const [allMembersList, setAllMembersList] = useState([]);

  const getAllUsersFunc = async () => {
    let allUsersList = AuthService.getInstance().GetAllUsers();

    const allUsers = await allUsersList;

    setAllMembersList(allUsers.data.users);
  };

  useEffect(() => {
    getAllUsersFunc();
  }, []);

  return (
    <div>
      {spaceDetails.admins.some((admin) => admin.id === userDetails.id) ===
        true || userDetails.role === "admin" ? (
        <div
          style={{
            marginTop: 50,
            width: "100%",
            backgroundColor: "rgba(44, 44, 49, 0.8)",
            borderRadius: 10,
            position: "relative",
            height: 600,
            display: "flex",
          }}
        >
          {/* -----------------Sidemenu------------- */}
          <div
            style={{
              width: 205,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              height: "100%",
              borderRadius: "10px 0 0 10px",
              padding: "26px 21px",
            }}
          >
            <ListItem
              onClick={() => setActiveOption(1)}
              button
              style={
                activeOption === 1
                  ? {
                      display: "flex",
                      padding: "11px 26px",
                      border: "1px solid #fff",
                      borderRadius: 5,
                    }
                  : {
                      display: "flex",
                      padding: "12px 26px",
                    }
              }
            >
              <PeopleIcon style={{ color: "#fff", transform: "scale(1.2)" }} />
              <div
                style={
                  activeOption === 1
                    ? {
                        fontSize: 16,
                        fontFamily: "Inter",
                        fontWeight: 700,
                        lineHeight: "150%",
                        color: "#fff",
                        marginLeft: 16,
                      }
                    : {
                        fontSize: 16,
                        fontFamily: "Inter",
                        fontWeight: 400,
                        lineHeight: "150%",
                        color: "#fff",
                        marginLeft: 16,
                      }
                }
              >
                Members
              </div>
            </ListItem>

            <ListItem
              onClick={() => setActiveOption(2)}
              button
              style={
                activeOption === 2
                  ? {
                      display: "flex",
                      padding: "11px 26px",
                      border: "1px solid #fff",
                      borderRadius: 5,
                      marginTop: 10,
                    }
                  : {
                      display: "flex",
                      padding: "12px 26px",
                      marginTop: 10,
                    }
              }
            >
              <SettingsIcon
                style={{ color: "#fff", transform: "scale(1.2)" }}
              />
              <div
                style={
                  activeOption === 2
                    ? {
                        fontSize: 16,
                        fontFamily: "Inter",
                        fontWeight: 700,
                        lineHeight: "150%",
                        color: "#fff",
                        marginLeft: 16,
                      }
                    : {
                        fontSize: 16,
                        fontFamily: "Inter",
                        fontWeight: 400,
                        lineHeight: "150%",
                        color: "#fff",
                        marginLeft: 16,
                      }
                }
              >
                Settings
              </div>
            </ListItem>
          </div>

          {/* ----------------------Members------------------------ */}
          <div style={{ padding: "24px 36px", width: "100%" }}>
            {/* ---------------Member list------------------- */}
            {activeOption === 1 && (
              <SpaceMembersList
                spaceDetails={spaceDetails}
                allMembersList={allMembersList}
                addUserClickedmainFunc={addUserClickedmainFunc}
              />
            )}

            {/* ------------------SpaceSettings------------------ */}
            {activeOption === 2 && (
              <SpaceSettings
                spaceDetails={spaceDetails}
                spaceEditedFunc={spaceEditedFunc}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            marginTop: 50,
            width: "100%",
            backgroundColor: "rgba(44, 44, 49, 0.8)",
            borderRadius: 10,
            position: "relative",
            height: 600,
            display: "flex",
          }}
        >
          <div
            style={{
              fontSize: 36,
              fontFamily: "Inter",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              color: "#ffffff",
            }}
          >
            Only Admins can access this Page!
          </div>
        </div>
      )}
    </div>
  );
};

export default SpaceDetailsSidebar;
