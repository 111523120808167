import { Grid } from "@mui/material";
import spaceImage from "../../../assets/so_logo.png";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import emptyGridImage from "../../../assets/emptyGridImage.png";
import AuthService from "../../../services/AuthService";
import MaterialButton from "../Button/Button";

const SpacesGrid = ({ createdSpaces, userDetails }) => {
  const spaceAdmin = createdSpaces.map((space) => {
    const searchSpaceAdmin = space.admins.map((admin) => {
      return admin.id.includes(userDetails.id);
    });

    return searchSpaceAdmin;
  });

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(44, 44, 49, 0.8)",
        overflow: "scroll",
      }}
    >
      <div
        style={{
          position: "relative",
          maxWidth: 1120,
          margin: "100px auto",
        }}
      >
        {/* ----------------Top header-------------- */}

        {createdSpaces.length > 0 ? (
          <div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                marginBottom: 40,
                padding: "0 34px 0 0",
              }}
            >
              {userDetails.role === "admin" && (
                <Link to="/space/create">
                  <div
                    style={{
                      width: 165,
                    }}
                  >
                    <MaterialButton
                      variant={"contained"}
                      label={"Create Space"}
                    />
                  </div>
                </Link>
              )}

              <div
                style={{
                  width: 120,
                  marginLeft: 20,
                }}
                onClick={() => {
                  AuthService.getInstance().logout();
                  window.location.replace("/");
                }}
              >
                <MaterialButton variant={"contained"} label={"Logout"} />
              </div>
            </div>

            {/* -------------------Grid----------------------- */}
            <Grid container>
              {createdSpaces.map((space, index) => (
                <Grid item xs={12} sm={4} key={index} style={{ padding: 33 }}>
                  <div
                    onClick={() => window.location.replace("/" + space.id)}
                    style={{
                      height: 187,
                      width: "100%",
                      backgroundColor: "rgba(44, 44, 49, 0.8)",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={spaceImage}
                      alt="spaceImage"
                      style={{ width: 100, height: 100 }}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 5,
                      backgroundColor: "rgba(44, 44, 49, 0.9)",
                      height: 41,
                      padding: "0 13px",
                      marginTop: 12,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 16,
                        fontFamily: "Inter",
                        fontWeight: 600,
                        lineHeight: "150%",
                        color: "#FFFFFF",
                      }}
                    >
                      {space.name}
                    </div>

                    {(userDetails.role === "admin" ||
                      spaceAdmin[0].includes(true)) && (
                      <div>
                        <Link to={"/space/edit/" + space.id}>
                          <SettingsIcon
                            style={{
                              color: "#fff",
                              cursor: "pointer",
                              marginTop: 6,
                            }}
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 220px)",
            }}
          >
            <img
              src={emptyGridImage}
              alt="emptyGridImage"
              style={{ height: 440 }}
            />

            <h2
              style={{
                color: "#ffffff",
                fontWeight: 700,
                fontSize: 36,
                fontFamily: "Inter",
              }}
            >
              Hmm.. looks like you’ve not been added to a space yet!
            </h2>

            <div
              style={{
                color: "#ffffff",
                fontWeight: 400,
                fontSize: 24,
                fontFamily: "Inter",
                marginTop: 40,
                maxWidth: 680,
                textAlign: "center",
              }}
            >
              Ask your team admin to invite you to a space first, then you can
              join it through the invite link sent to your email.
            </div>

            <div style={{ display: "flex", marginTop: 40 }}>
              <div
                onClick={() => {
                  AuthService.getInstance().logout();
                  window.location.replace("/");
                }}
                style={{ width: 160 }}
              >
                <MaterialButton variant={"contained"} label={"Logout"} />
              </div>

              {userDetails.role === "admin" && (
                <Link to="/space/create">
                  <div
                    style={{
                      width: 165,
                      marginLeft: 20,
                    }}
                  >
                    <MaterialButton
                      variant={"contained"}
                      label={"Create Space"}
                    />
                  </div>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpacesGrid;
