import { Grid } from "@mui/material";
import spaceImage from "../../../assets/so_logo.png";
import MaterialButton from "../Button/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SpaceDetailsSidebar from "./SpaceDetailsSidebar";
import { useEffect, useState } from "react";
import moment from "moment";
import background from "../../../assets/background.jpg";
import AuthService from "../../../services/AuthService";
import CircularProgress from "@mui/material/CircularProgress";

const SpaceDetails = () => {
  const navigate = useNavigate();

  const [spaceDetails, setSpaceDetails] = useState(null);
  const [spaceMembersArray, setSpaceMembersArray] = useState([]);
  const [spaceName, setSpaceName] = useState("");

  let { id } = useParams();

  const spaceDetailsFunc = async () => {
    let spaceRequest = AuthService.getInstance().GetSpaceDetail(id);

    var spaceData = await spaceRequest;
    setSpaceDetails(spaceData.data.space);
    console.log("spaceDetails ID", spaceDetails);
  };

  // --------Check if user is added parent---------
  const addUserClickedmainFunc = (membersArray) => {
    setSpaceMembersArray(membersArray);
  };

  // --------Check if space is edited---------
  const spaceEditedFunc = (spaceEdited) => {
    setSpaceName(spaceEdited);
  };

  useEffect(() => {
    spaceDetailsFunc();
  }, [spaceMembersArray, spaceName]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundImage: "url(" + background + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          backgroundColor: "rgba(44, 44, 49, 0.8)",
          padding: "70px 30px 15px 30px",
          height: "100%",
          width: "100%",
          overflow: "scroll",
        }}
      >
        {/* --------------------------Space info grid--------------------------- */}
        {spaceDetails !== null ? (
          <div
            style={{
              maxWidth: "80%",
              margin: "0 auto",
            }}
          >
            <div>
              <Grid container>
                <Grid item sm={6} md={3}>
                  <div
                    style={{
                      height: "100%",
                      width: "90%",
                      backgroundColor: "rgba(44, 44, 49, 0.8)",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={spaceImage}
                      alt="spaceImage"
                      style={{ width: 100, height: 100 }}
                    />
                  </div>
                </Grid>

                <Grid
                  item
                  sm={3}
                  md={2}
                  style={{
                    fontSize: 18,
                    fontFamily: "Inter",
                    fontWeight: 400,
                    lineHeight: "150%",
                    color: "#ffffff",
                  }}
                >
                  <div style={{ marginTop: 10 }}>Space Name:</div>
                  <div style={{ marginTop: 20 }}>Created By:</div>
                  <div style={{ marginTop: 20 }}>Date Created:</div>
                  <div style={{ marginTop: 20 }}> Total Members:</div>
                </Grid>

                <Grid
                  item
                  sm={3}
                  md={2}
                  style={{
                    fontSize: 18,
                    fontFamily: "Inter",
                    fontWeight: 700,
                    lineHeight: "150%",
                    color: "#ffffff",
                    // marginLeft: -30,
                  }}
                >
                  <div style={{ marginTop: 10 }}>{spaceDetails.name}</div>
                  <div style={{ marginTop: 20 }}>
                    {spaceDetails.members[0].playerName}
                  </div>
                  <div style={{ marginTop: 20 }}>
                    {moment(spaceDetails.createdAt).format("LL")}
                  </div>
                  <div style={{ marginTop: 20 }}>
                    {spaceDetails.members.length}
                  </div>
                </Grid>

                <Grid
                  item
                  sm={12}
                  md={5}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    onClick={() =>
                      window.location.replace("/" + spaceDetails.id)
                    }
                    style={{ width: 160 }}
                  >
                    <MaterialButton
                      variant={"contained"}
                      label={"Enter Space"}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <SpaceDetailsSidebar
              spaceDetails={spaceDetails}
              addUserClickedmainFunc={addUserClickedmainFunc}
              spaceEditedFunc={spaceEditedFunc}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                animationDuration: "550ms",
                color: "#ffffff",
              }}
              size={40}
              thickness={4}
            />
          </div>
        )}

        <ArrowBackIosIcon
          onClick={() => navigate(-1)}
          style={{
            color: "#fff",
            transform: "scale(1.2)",
            position: "absolute",
            top: 74,
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

export default SpaceDetails;
