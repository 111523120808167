const buttonStyle = (theme) => ({
  buttonContained: {
    width: "100%",
    height: "40px !important",
    textTransform: "none !important",
    color: "#fffff !important",
    fontSize: "1rem !important",
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    lineHeight: "150% !important",
    // marginTop: "15px !important",
    background: "#787DF9 !important",
  },

  buttonOutlined: {
    width: "100%",
    height: "40px !important",
    textTransform: "none !important",
    color: "#C4C4C4 !important",
    fontSize: "1rem !important",
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    lineHeight: "150% !important",
    // marginTop: "15px !important",
    border: "1px solid #ffffff !important",
  },
});

export default buttonStyle;
