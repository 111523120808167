const dashboardStyles = (theme) => ({
  textField: {
    width: "100%",
    border: "none",
    backgroundColor: "rgba(0, 0, 0, 0.43)",
    color: "#fff",
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "150%",
    padding: "11px 9px",
    borderRadius: 5,
    marginTop: 5,
  },

  button: {
    width: "100%",
    height: "40px !important",
    textTransform: "none !important",
    color: "#fffff !important",
    fontSize: "1rem !important",
    fontFamily: "Inter !important",
    fontWeight: "600 !important",
    lineHeight: "150% !important",
    background: "#D20000 !important",
  },
});

export default dashboardStyles;
