import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik } from "formik";
import loginStyle from "./LoginStyle";
import { useToast } from "@chakra-ui/react";
import GoogleAuth from "./GoogleAuth";
import AuthService from "../../services/AuthService";
import SOImage from "../../assets/so.png";

const useStyles = makeStyles(loginStyle);

const Login = () => {
  const styles = useStyles();

  const toast = useToast();

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <img src={SOImage} alt="logo" />
        </div>

        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }

            if (values.password.length < 8) {
              errors.password = "Enter at least 8 characters";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await AuthService.getInstance().login(
                values.email,
                values.password
              );
              window.location.reload();
            } catch (error) {
              if (error.response.data && error.response.data.error) {
                toast({
                  title: error.response.data.error,
                  status: "error",
                  isClosable: false,
                  position: "top",
                  duration: 2000,
                  id: "login-error",
                });
              }
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} style={{ marginTop: 40 }}>
              {/* -----------------Textinputs----------------------- */}
              <TextField
                size="small"
                error={errors.email && touched.email && errors.email}
                helperText={errors.email && touched.email && errors.email}
                label="Email Address"
                id="email"
                variant="outlined"
                margin="normal"
                fullWidth
                className={styles.textField}
                InputProps={{
                  style: {
                    color: "#ffffff ",
                    width: "100% ",
                    fontSize: 16,
                  },
                }}
                InputLabelProps={{
                  style:
                    errors.email && touched.email && errors.email
                      ? { color: "red", fontSize: 16 }
                      : { color: "#ffffff", fontSize: 16 },
                }}
                onChange={handleChange}
                value={values.email}
              />

              <TextField
                size="small"
                error={errors.password && touched.password && errors.password}
                helperText={
                  errors.password && touched.password && errors.password
                }
                label="Password"
                id="password"
                variant="outlined"
                margin="normal"
                fullWidth
                className={styles.textField}
                InputProps={{
                  style: {
                    color: "#ffffff ",
                    width: "100% ",
                    fontSize: 16,
                  },
                }}
                InputLabelProps={{
                  style:
                    errors.password && touched.password && errors.password
                      ? { color: "red", fontSize: 16 }
                      : { color: "#ffffff", fontSize: 16 },
                }}
                onChange={handleChange}
                type="password"
              />

              <div style={{ position: "relative" }}>
                <Button
                  type="submit"
                  className={
                    isSubmitting
                      ? styles.loginButtonLoading
                      : styles.loginButton
                  }
                  variant="contained"
                >
                  Login
                </Button>

                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    style={{
                      color: "#ffffff",
                      position: "absolute",
                      top: "45%",
                      left: "48%",
                    }}
                  />
                )}
              </div>
            </form>
          )}
        </Formik>

        <div className={styles.signUpText}>
          <Link to="/email-verification" style={{ textDecoration: "none" }}>
            <span className={styles.getStarted}>Forgot Password?</span>
          </Link>
        </div>

        <div className={styles.orText}>OR</div>

        {/* ----------------------------Google auth------------------------------ */}

        <GoogleAuth />

        {/* -----------------------------------Sign up---------------------------------------------------- */}

        {/* <div className={styles.signUpText}>
          Don’t have an account?{" "}
          <Link to="/register" style={{ textDecoration: "none" }}>
            <span className={styles.getStarted}>Get started</span>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
